header {
    position: relative;
    z-index: 10;
}

.lang-bar {
    background-color: var(--accent);
    min-height: .5rem;
}

.lang-bar > div {
    display: flex;
    justify-content: flex-end;
}

.lang-bar span {
    margin: .3rem 0;
    font-size: .8em;
    font-weight: 600;
}

.navigation {
    padding: 1rem 0;
    background-color: var(--background);
}

.navigation > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    height: 5em;
}
