.banner {
    background-color: var(--accent-light);
    padding: 2rem 0;
}

.info {
    margin: 0 0 1em 0;
    font-weight: bold;
    font-style: italic;
}

.description {
    margin: 0;
}

.gallery > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.picture {
    padding: 6rem 0 0;
    overflow: hidden;
}

.picture img {
    max-width: 100%;
    border-radius: 1rem;
}

.picture video {
    max-width: 100%;
}

.navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem 2rem 0;
    text-align: center;
}

.navigation a:nth-child(2) {
    width: 100%;
    order: -1;
    margin: 0 0 1rem;
}

.navigation a:first-child, .navigation a:last-child {
    flex: 1;
}

.navigation a:first-child{
    margin: 0 .5rem 0 0;
}

.navigation a:last-child{
    margin: 0 0 0 .5rem;
}

@media(min-width: 640px){
    .navigation a:nth-child(2) {
        width: auto;
        order: 0;
        margin: 0;
    }

    .navigation a:first-child, .navigation a:last-child {
        flex: 0;
    }
}
