footer {
    background-color: var(--background-inverted);
    color: var(--text-inverted);
}

footer > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyright {
    font-size: .8em;
}

.social {
    display: flex;
}

.social img {
    width: 24px;
    height: 24px;
    margin: 1em .2em;
}

.social svg {
    fill: #fff;
    width: 24px;
    margin: 1em .2em;
}
