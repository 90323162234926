.banner {
    background-color: var(--accent-light);
    padding: 2rem 0 12rem;
}

.info {
    margin: 0 0 1em 0;
    font-weight: bold;
    font-style: italic;
}

.description {
    margin: 0;
}

.gallery {
    margin-top: -10rem;
}

.gallery > div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.picture {
    flex: auto;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all .4s;
    will-change: transform;
}

.picture:hover {
    transform: scale(1.05);
}

.picture img {
    width: 100%;
    object-fit: cover;
    padding: .5rem;
    background-color: var(--background);
    border-radius: 5px;
}

.picture video {
    max-width: 100%;
}

.navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem 2rem 0;
    text-align: center;
}

.navigation a:nth-child(2) {
    width: 100%;
    order: -1;
    margin: 0 0 1rem;
}

.navigation a:first-child, .navigation a:last-child {
    flex: 1;
}

.navigation a:first-child{
    margin: 0 .5rem 0 0;
}

.navigation a:last-child{
    margin: 0 0 0 .5rem;
}

@media(min-width: 640px){
    .gallery > div {
        display: block;
        columns: 3 20rem;
        column-gap: 1rem;
    }

    .navigation a:nth-child(2) {
        width: auto;
        order: 0;
        margin: 0;
    }

    .navigation a:first-child, .navigation a:last-child {
        flex: 0;
    }
}
