.list {
    margin: 4em 0 0 0;
}

.list > div {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em 2em;
}

.card {
    cursor: pointer;
    transition: all .4s;
}

.card:hover {
    transform: scale(1.05);
}

.card .ratio {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.card img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
}

.card h1, .card p {
    margin: 0;
    padding: .5rem .5rem;
}

.card h1 {
    text-align: center;
}

.card p {
    text-align: center;
    font-size: 1.2em;
}

@media(min-width: 640px){
    .list > div {
        grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
        grid-auto-columns: 1fr minmax(20em, 1fr);
    }

    .card h1 {
        text-align: left;
    }

    .card p {
        text-align: left;
    }
}
