.banner {
    background: linear-gradient(
        to bottom, 
        var(--accent-light) 0% 90%,
        #fff 90%
    );
    margin-bottom: 3em;
}

.banner > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner img {
    /*transform: translateY(3em);*/
    width: 20rem;
    height: 20rem;
    object-fit: cover;
}

.info {
    margin: 3rem 0 0;
    order: -1;
}

.info h1, .service h2 {
    margin: 0 0 1.5rem;
}

.description {
    margin: 0 0 2rem;
}

.description a {
    text-decoration: underline;
}

.inquire {
    display: block;
    margin: 0 auto;
}

.service {
    margin: 3rem 0 0;
}

.service > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service img {
    width: 20rem;
    margin: .5rem 0 0;
}

.accordion {
    margin-top: 3rem;
}

.item {
    border-bottom: 2px solid var(--accent);
}

.trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0;
}

.trigger h3 {
    margin: 0;
}

.trigger i {
    font-size: 32px;
}

.panel {
    max-height: 15rem;
    padding: 1rem 0 0;
    overflow: hidden;
    transition: max-height .4s, padding .4s;
}

.panel[hidden]{
    display: block;
    max-height: 0;
    padding: 0;
}

.panel p {
    margin: 0 0 1rem;
}

.panel a {
    text-decoration: underline;
}

.cards {
    display: flex;
    margin-bottom: 1rem;
}

.card {
    flex: 1;
    padding: 1rem;
    background-color: var(--accent-light);
    border-radius: .5rem;
    text-align: center;
}
.card:not(:last-child){
    margin-right: 1rem;
}

.card .title {
    font-weight: bold;
    margin: 0 0 1rem;
}

.card .detail {
    font-style: italic;
}

@media(min-width: 640px){
    .banner {
        margin: 0 0 2rem;
    }

    .banner > div {
        flex-direction: row;
        align-items: flex-start;
    }

    .banner > div > div {
        align-items: flex-start;
    }

    .banner img {
        transform: translateY(3rem);
    }

    .info {
        margin: 3rem 0 2rem 2.5em;
        order: 0;
    }

    .description {
        margin: 0 0 2rem;
    }

    .inquire {
        display: block;
        margin: 0;
    }

    .service {
        margin: 4rem 0 0;
    }

    .service > div {
        flex-direction: row;
        align-items: flex-start;
    }

    .service > div > div {
        align-items: flex-start;
    }

    .service img {
        margin: 0 0 0 6rem;
    }
}
