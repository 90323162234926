.button {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
}

.button i {
    font-size: 4em;
    display: block;
}

.menu {
    visibility: hidden;
    display: grid;
    grid-template-columns: auto;
    align-self: flex-end;
    gap: 2em 4em;
    padding: 2em 1em;
    border-bottom: .5em solid var(--accent-light);
    position: absolute;
    z-index: -10;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background);
    transition: transform .4s, visibility 0s linear .4s;
}

.menu.open {
    visibility: visible;
    transform: translateY(100%);
    transition: transform .4s, visibility 0s linear 0s;
}

.menu a.active {
    font-weight: bold;
}

.overlay {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -20;
    height: 100vh;
    width: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity .4s, visibility 0s .4s ;
}

.menu.open + .overlay {
    visibility: visible;
    opacity: .7;
    transition: opacity .4s;
}

@media(min-width: 640px){
    .button {
        display: none;
    }

    .menu {
        visibility: visible;
        grid-template-columns: repeat(4, auto);
        padding: 0;
        border: none;
        position: static;
        z-index: auto;
        transition: none;
    }

    .menu.open {
        transform: none;
        transition: none;
    }

    .menu .message {
        display: none;
    }

    .overlay {
        display: none;
    }
}
