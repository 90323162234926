@import-normalize;

:root {
    --accent: #dcc7b7;
    --accent-light: #f1e8e1;
    --background: #fff;
    --background-inverted: #000;
    --text: #000;
    --text-inverted: #fff;
}

html {
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
}

body.locked {
    overflow: hidden;
}

body > div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 1rem 0
}

h1 {
    font-size: 1.5em;
}

p {
    margin: 0 0 3rem 0
}

a {
    color: inherit;
    text-decoration: inherit;
}

img {
    vertical-align: bottom;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.wrapper {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 70rem;
}

.img-border {
    padding: .5rem;
    border-radius: 5px;
    background-color: var(--background);
}

.accent-banner {
    background-color: var(--accent-light);
    position: absolute;
    left: 0;
    right: 0;
    height: 20rem;
    z-index: -10;
}

.btn {
    display: inline-block;
    padding: .4rem 1rem;
    background-color: var(--background-inverted);
    color: var(--text-inverted);
    border: none;
    border-radius: .5rem;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Jost', sans-serif;
    line-height: 1.15;
}

@media(min-width: 640px){
    body.locked {
        overflow: auto;
    }
}
