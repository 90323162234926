.banner {
    background-color: var(--accent-light);
    margin-bottom: 3em;
}

.info {
    display: flex;
    flex-direction: column;
    transform: translateY(3em);
}

.info img {
    width: 18rem;
    align-self: center;
}

.info p {
    margin: 0 0 2rem;
}

.info .text {
    order: -1;
}

.info a {
    text-decoration: underline;
}

.service {
    margin: 5em 0 0;
}

.service > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service h2, .banner h1 {
    margin: 0 0 1.5rem;
}

@media(min-width: 640px){
    .service h1, .banner h1 {
        text-align: left;
    }

    .banner {
        padding: 3rem 0 1rem;
    }

    .info {
        flex-direction: row;
        align-items: flex-start;
        transform: none;
    }

    .info img {
        align-self: auto;
    }

    .info .text {
        margin-left: 2.5em;
        order: 0;
    }

    .service {
        margin: 3em 0 0;
    }

    .service > div {
        align-items: flex-start;
    }
}
